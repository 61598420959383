// import { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import Home from './pages/Home';
import Login from './pages/Login';
import Admin from './pages/Admin';
import NoMatch from './pages/NoMatch';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin"  element={
          <PrivateRoute>
            <Admin />
          </PrivateRoute>
        }/>
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
