import React from 'react'

export const Button = ({ title, type, icon, text, route }) => {

    return (
        <a href={`#${route}`} >
            <button className='bg-white border-2 border-black rounded-xl p-2 flex flex-row items-center w-36 hover:bg-[#377787]'>
                <div className='mr-1' >
                    <img className='max-w-xs m-auto' src={icon} alt={text} />
                </div>
                <div className='text-left' >
                    <p className='text-black text-xs'>{title}</p>
                    <p className='text-black leading-4'>{text}</p>
                </div>
            </button>
        </a>
    )
}
