import { useState, useEffect } from 'react'
import { requestForToken, isSupported } from '../../utils/firebase';
import { addUserToNotification } from '../../services/websiteService';

const NotificationComp = ({ websiteInfo }) => {
    const [isEnableNotificationActive, setIsEnableNotificationActive] = useState(false)

    const handleRequestToken = async () => {


        if (!isSupported()) {
            alert("browser does not support notification")
            return
        }
        const token = await requestForToken()
        console.log("token: ", token)
        if (token) {
            
            const addResult = await addUserToNotification({
                url: websiteInfo.attributes.url,
                notificationToken: token,
                isActive: true,
                website: { id: websiteInfo.id }
            })

            if (addResult) {
                window.localStorage.setItem('notificationToken', token)
                window.location.replace(websiteInfo.attributes.redirectUrl)
            }
        } else {
            console.log("token is null")
        }
    }

    useEffect(() => {
        if (isSupported()) {
            setIsEnableNotificationActive(websiteInfo?.attributes?.url && Notification.permission !== "granted" ? false : true)
        } else {
            setIsEnableNotificationActive(false)
        }

        console.log(`notification token: ${localStorage.getItem('notificationToken')}`)
        if (isSupported() && websiteInfo.attributes) {
            console.log(Notification.permission)
            if (Notification.permission === 'granted' && !localStorage.getItem('notificationToken')) {
                handleRequestToken()
            }
        }

    }, [websiteInfo])
    return (
        <div className='justify-center py-10 px-3'>
            <div className='mb-4 text-center' >
                <p className='font-semibold' >Bildirimleri aktif ederek en güncel kampanyalardan haberdar olun. Bildirimleri aktif ettiğinizde otomatik yönlendirme tekrardan aktif olacaktır.</p>
            </div>
            <div className='grid grid-cols-2 justify-center gap-4'>
                <div className='flex justify-center align-middle'>
                    <button disabled={isEnableNotificationActive} onClick={() => { handleRequestToken() }} type="button" className="w-full text-white bg-gradient-to-br from-green-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Bildirimleri Aç</button>
                </div>
                <div className='flex text-center self-center' >
                    <a href={websiteInfo?.attributes?.redirectUrl} type="button" className="w-full text-white bg-gradient-to-br from-green-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Siteye Git!</a>
                </div>
            </div>
        </div>
    )
}

export default NotificationComp