import { useState, useEffect } from 'react'
import { MdNotificationsActive } from "react-icons/md";
import { createNotification } from '../../services/websiteService';
const titleRegex = /^.{1,100}$/;
const bodyRegex = /^.{1,500}$/;
const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

const NotificationSendForm = ({ websiteInfo, websiteId }) => {
    
    const [notification, setNotification] = useState({
        title: '',
        body: '',
        image: '',
        isSend: false,
        url: '',
        website: null
    })

    const [isFormValid, setIsFormValid] = useState({
        title: false,
        body: false,
        image: true
    })

    const handleTitle = (e) => {
        e.preventDefault()
        setNotification(prevState => ({
            ...prevState,
            title: e.target.value
        }))
    }

    const handleBody = (e) => {
        e.preventDefault()
        setNotification(prevState => ({
            ...prevState,
            body: e.target.value
        }))
    }

    const handleImage = (e) => {
        e.preventDefault()
        setNotification(prevState => ({
            ...prevState,
            image: e.target.value
        }))
    }

    const handleSubmit = async () => {

        const result = await createNotification(notification)
        if (result) {
            console.log('Notification created successfully')
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        } else {
            console.log('Notification creation failed')
        }
    }

    useEffect(() => {

        setIsFormValid(prevState => ({
            ...prevState,
            title: titleRegex.test(notification.title)
        }))

        setIsFormValid(prevState => ({
            ...prevState,
            body: bodyRegex.test(notification.body)
        }))

        setIsFormValid(prevState => ({
            ...prevState,
            image: urlRegex.test(notification.image)
        }))

        if (notification.image === '') {
            setIsFormValid(prevState => ({
                ...prevState,
                image: true
            }))
        }
    }
        , [notification])

    useEffect(() => {
        if (websiteInfo) {
            setNotification(prevState => ({
                ...prevState,
                url: websiteInfo.url,
                website: {id: websiteId}
            }))

        }
    }, [websiteInfo, websiteId])


    return (
        <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12">
            <a href="/" className="mb-4 bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-blue-400">
                <MdNotificationsActive size={24} className='mr-2' />
                Notification
            </a>
            <label
                htmlFor="input-group-1"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title:</label>
            <input
                onChange={handleTitle}
                type="text"
                id="disabled-input-2"
                aria-label="disabled input 2"
                className="mb-4 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block 
                w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 
                dark:focus:border-blue-500" />

            <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Body:</label>
            <textarea
                onChange={handleBody}
                id="message"
                rows="4"
                className="mb-4 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 
                focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 
                dark:focus:border-blue-500"
                placeholder="Write your message here..." />
            <label
                htmlFor="input-group-1"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image URL:</label>
            <input
                onChange={handleImage}
                type="text"
                id="disabled-input-2"
                aria-label="disabled input 2"
                className="mb-4 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block 
                w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 
                dark:focus:border-blue-500" />
            <button
                disabled={!(isFormValid.title && isFormValid.body && isFormValid.image)}
                onClick={handleSubmit}
                type="button"
                className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none 
                focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg 
                text-sm px-5 py-2.5 text-center ">Create</button>
        </div>
    )
}

export default NotificationSendForm