import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../../store/authSlice'

const AdminNavBar = () => {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const handleLogout = (e) => {

        dispatch(logout()).unwrap().then(() => {
            navigate("/login");
            window.location.reload();
        })
    }


    return (

        <nav className="bg-white border-gray-200 dark:bg-gray-900">
            <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
                <a href="/" className="flex items-center">
                    <img src="./apple-touch-icon.png" className="h-8 mr-3" alt="Company Logo" />
                    {/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span> */}
                </a>
                <div className="flex items-center">
                    <button
                        onClick={handleLogout}
                        href="#"
                        className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                        Logout
                    </button>
                </div>
            </div>
        </nav>

    )
}

export default AdminNavBar