import { useState, useEffect } from 'react'
import { axiosWebsiteInstance } from '../config/axios'

import AdminNavBar from '../components/admin/AdminNavBar'
import UrlUpdateForm from '../components/admin/UrlUpdateForm'
import NotificationSendForm from '../components/admin/NotificationSendForm'
import NotificationTable from '../components/admin/NotificationTable'

const Admin = () => {

  const [websiteInfo, setWebsiteInfo] = useState({})
  const [websiteId, setWebsiteId] = useState()
  
  useEffect(() => {
    request()
  }, [])

  const request = async () => {
    const result = await axiosWebsiteInstance.get('/api/websites');
    
    setWebsiteInfo(result.data.data.attributes)
    setWebsiteId(result.data.data.id)
  }

  return (
    <section className="bg-white dark:bg-gray-900 h-auto">
      <AdminNavBar />
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
        <div className="grid md:grid-cols-2 gap-8 mb-8">

          <UrlUpdateForm websiteId={websiteId} websiteInfo={websiteInfo} />
          <NotificationSendForm websiteInfo={websiteInfo} websiteId={websiteId}/>
          <div className='col-span-2 h-[415px] bg-gray-100 dark:bg-gray-800 dark:text-gray-100 text-gray-800 justify-center block overflow-y-scroll rounded-lg'>
            <NotificationTable />
          </div>
        </div>

      </div>
    </section>
  )
}

export default Admin