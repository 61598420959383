import { useState, useEffect } from 'react'

// import toast, { Toaster } from 'react-hot-toast';
import { getWebsiteMetadata } from '../services/websiteService';

import Navbar from '../components/user/Navbar'
import Slider from '../components/user/Slider'
import { CarouselComp } from '../components/user/Carousel';
import { Button } from '../components/user/Button'
import NotificationComp from '../components/user/Notification';

//image and icons
import img0 from '../assets/images/phone.png'
import img1 from '../assets/images/sports_assets.png'
import img2 from '../assets/images/image-2.png'
import img3 from '../assets/images/video-frame.png'

import logoDark from '../assets/images/logo-dark.png'
import logoLight from '../assets/images/logo-light.png'
//icons
import appleIcon from '../assets/icons/apple-logo.svg'
import huaweiIcon from '../assets/icons/huawei-logo.svg'
import playstoreIcon from '../assets/icons/playstore-logo.svg'
import windowsIcon from '../assets/icons/windows-logo.svg'
import tickIcon from '../assets/icons/tick.svg'
import twitterLogo from '../assets/icons/twitter.svg'
import facebookLogo from '../assets/icons/facebook.svg'
import instagramLogo from '../assets/icons/instagram.png'
import telegram from '../assets/icons/telegram.svg'
import androidVideo from '../assets/videos/android.mp4'
import iosVideo from '../assets/videos/ios.mp4'

const Home = () => {

    const [websiteInfo, setWebsiteInfo] = useState({})


    const [activeTab, setActiveTab] = useState('android');
    const [videoSource, setVideoSource] = useState(androidVideo)

    const handleWebsiteMetadata = async () => {
        const data = await getWebsiteMetadata()
        if (data) {
            setWebsiteInfo(data)
        }
    }

    useEffect(() => {
        handleWebsiteMetadata()
    }, [])

    const handler = (e) => {
        setActiveTab(activeTab === 'android' ? 'ios' : 'android')
        setVideoSource(videoSource === androidVideo ? iosVideo : androidVideo)
    }

    return (
        <div className="max-w-[1440px] m-auto">
            <Navbar />
            <div className='mt-24' >
                {document.referrer.startsWith("android-app://") ||
                    navigator.standalone || window.matchMedia("(display-mode: standalone)").matches ?
                    <NotificationComp websiteInfo={websiteInfo} /> : <></>
                }
                
            </div>
            <div className='main' >
                <section className='flex flex-col section1 justify-center text-center lg:pt-52 pt-40 lg:pb-24 pb-10 items-center'>
                    <div className='flex flex-col max-w-screen-xl' >
                        <h1 className='lg:text-6xl text-3xl font-bold mb-5' >SEKABET HER ZAMAN,<br /> HER YERDE SENİNLE!</h1>
                        <h2 className='' >SekaBet’in zengin bahis seçeneklerine dilediğin an,<br /> dilediğin yerden ulaş. Sınırsız bahisin keyfini çıkar!</h2>
                        <div className='flex flex-row  justify-center items-center mt-10 gap-3 mb-10' >
                            <Button title={'Download on the'} type={'primary'} icon={appleIcon} text={'App Store'} route={'appstore-slider'} />
                            <Button title={'GET IT ON'} type={'primary'} icon={playstoreIcon} text={'Google Play'} route={'google-slider'} />

                        </div>
                        <div className='row overflow-hidden'>
                            <div className='relative z-0 m-auto flex z-20 lg:w-full w-[140%] ms-[-20%] lg:ms-0'>
                                <img className='relative z-10 m-auto' src={img0} alt='sekabet banner mobil' />
                            </div>
                            <div className=' -top-lg-12 -top-4 relative z-10' >
                                <h1 className='text-lg-9xl  text-5xl gradient-text-color pb-5'>SEKABET'İ İNDİR!</h1>
                                <p className='lg:text-xl text-xs' >Uygun platformu seçin ve SekaBet <br />uygulamasını güvenli bir şekilde indirin.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='lg:pb-24 pb-10' id='appstore-slider'>
                    <div className='flex mb-10' >
                        <img className='m-auto max-w-[40px]' src={require('../assets/images/apple-transparent-icon.png')} />
                    </div>
                    <div className='mb-10 px-5' >
                        <h2 className='text-lg-6xl text-2xl text-center font-bold uppercase' >SekaBet İos'a <br />nasıl İndİrİlİr?</h2>
                    </div>
                    <div className='lg:px-36 px-5' >
                        <CarouselComp />
                    </div>
                </section>
                <section className='lg:pb-24 pb-10' id='google-slider'>
                    <div className='flex mb-10' >
                        <img className='m-auto max-w-[40px]' src={require('../assets/images/android-transparent-icon.png')} />
                    </div>
                    <div className='mb-10 px-5' >
                        <h2 className='text-lg-6xl text-2xl text-center font-bold uppercase' >SekaBet Android'e <br />nasıl İndİrİlİr?</h2>
                    </div>
                    <div className='lg:px-36 px-5 ' >
                        <CarouselComp platform='android' />
                    </div>
                </section>
                <section className='bg-color-dark flex flex-col lg:pb-24 pb-10' >
                    <div className='m-auto flex gap-5 mb-12 p-1 rounded-3xl border-2' style={{ borderColor: 'rgba(255, 255, 255, 0.07)' }}>
                        <div
                            className={`flex justify-center p-3 rounded-2xl ${activeTab === 'android' ? 'bg-[#363636]' : ''}`}
                            onClick={handler}>
                            <img
                                src={playstoreIcon}
                                className='mr-3 text-white'
                                style={{ filter: 'invert(42%) sepia(93%) saturate(1352%) hue-rotate(24deg) brightness(119%) contrast(119%)' }} />
                            <p className='uppercase text-[#EE9A1B]' >android</p>
                        </div>
                        <div
                            className={`flex justify-center p-3 rounded-2xl ${activeTab === 'ios' ? 'bg-[#363636]' : ''}`}
                            onClick={handler} >
                            <img
                                src={appleIcon}
                                className='mr-3'
                                style={{ filter: 'invert(42%) sepia(93%) saturate(1352%) hue-rotate(24deg) brightness(119%) contrast(119%)' }} />
                            <p className='uppercase text-[#EE9A1B]' >ios</p>
                        </div>
                    </div>
                    <div className='mb-6 px-5' >
                        <Player source={videoSource} key={activeTab} />
                    </div>
                </section>
                <section className='bg-color-dark flex flex-col pb-24'>
                    <h2 className='lg:text-4xl text-2xl text-center font-bold uppercase mb-6' >Kullanışlı, Güçlü Arayüz ve<br /> Çok ÇEŞİTLİ BAHİS SEÇENEKLERİ</h2>
                    <p className='text-center mb-10' >Son derece sade ve kullanışlı arayüz ile tanışın! Canlı casino, slotlar,<br /> spor oyunları ve daha bir çoğu SekaBet’te seni bekliyor.</p>
                    <div className='grid grid-cols-2 px-lg-36 px-5 gap-5' >
                        <div className='grid gap-5' >
                            <div className='row' >
                                <img className='w-full' src={require('../assets/images/option1.png')} />
                            </div>
                            <div className='row' >
                                <img className='w-full' src={require('../assets/images/option3.png')} />
                            </div>
                        </div>
                        <div className='grid gap-5' >
                            <div className='row' >
                                <img className='w-full' src={require('../assets/images/option2.png')} />
                            </div>
                            <div className='row' >
                                <img className='w-full' src={require('../assets/images/option4.png')} />
                            </div>
                        </div>
                    </div>
                </section>
                <footer className='px-lg-36 px-5'>
                    <div className='row' >
                        <div className='flex mb-6 justify-between' >
                            <img src={logoLight} alt='sekabet dark logo'></img>
                            <div className='grid grid-cols-4 gap-4'>
                                <a href='sekabetuygulama1.com' >
                                    <img src={twitterLogo} />
                                </a>
                                <a href='sekabetuygulama1.com' >
                                    <img src={facebookLogo} />
                                </a>
                                <a href='sekabetuygulama1.com' >
                                    <img src={instagramLogo} />
                                </a>
                                <a href='sekabetuygulama1.com' >
                                    <img src={telegram} />
                                </a>
                            </div>
                        </div>
                        <div>
                            <p className='mb-6' >
                                SekaBet’te son derece yüksek oranlarda bahis alın, kullanışlı ve güçlü arayüz ile hızlı bir şekilde kazanma fırsatını elde edin.
                            </p>
                            <p className='pb-6' >© 2023 Sekabet </p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Home

const Player = ({ key, source }) => {
    return (
        <video key={key} className="lg:w-3/4 border m-auto border-gray-200 rounded-lg dark:border-gray-700" controls>
            <source src={source} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    )
}