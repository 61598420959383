import { useState } from 'react'
import { CiLink } from "react-icons/ci";
import { MdBrowserUpdated } from "react-icons/md";
import { axiosWebsiteInstance } from '../../config/axios';

const UrlUpdateForm =  ({ websiteId, websiteInfo }) => {
    const [newLink, setNewLink] = useState("")
    const [response, setResponse] = useState({
        isError: false,
        message: ""
    })

    const handleOnChange = (e) => {
        setNewLink(e.target.value)
    }

    const handleCurrent = () => {
        setNewLink(websiteInfo.redirectUrl)
    }

    const handleSubmit = async () => {
        console.log(newLink)

        //contain https
        if (!newLink.includes('https://')) {
            setResponse({
                isError: true,
                message: 'url not contain "https://" '
            })
            setTimeout(() => {
                setResponse({
                    isError: false,
                    message: ''
                })
            }, 4000);
            return
        }

        if (websiteInfo.redirectUrl === newLink) {
            setResponse({
                isError: true,
                message: `You can't set current domain`
            })
            setTimeout(() => {
                setResponse({
                    isError: false,
                    message: ''
                })
            }, 3000);
            return
        }

        const result = await axiosWebsiteInstance.put(`/api/websites/${websiteId}`, {
            data: {
                redirectUrl: newLink
            }
        })
        console.log(result)
    }


    return (

        <div className=" bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12">

            <a href="/" className="mb-4 bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-gray-400">
                <MdBrowserUpdated size={24} className='mr-2' />
                Update Url
            </a>
            <div className='grid grid-cols-12' >
                <div className='col-span-9' >
                    <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your New Link:</label>
                    <div className="relative mb-6">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none cursor-pointer">
                            <CiLink size={24} className='text-gray-500' />
                        </div>
                        <input
                            value={newLink}
                            onChange={handleOnChange}
                            type="text"
                            id="input-group-1"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={`example: ${websiteInfo?.redirectUrl}`} />
                    </div>
                </div>
                <div className='flex items-center justify-center col-span-3' >
                    <button
                        onClick={handleCurrent}
                        type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Current</button>
                </div>
            </div>

            {response.isError &&
                <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-900 dark:text-red-400" role="alert">
                    <span className="font-medium">Error!</span> {response.message}
                </div>
            }
            <button
                onClick={handleSubmit}
                type="button" className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Change</button>
        </div>
    )
}

export default UrlUpdateForm