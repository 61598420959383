// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebaseConfig from "../config/firebase.config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const app = initializeApp(firebaseConfig);

//check if browser suppert to firebase messageing or notification protocol
export const isSupported = () =>
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window



const analytics = getAnalytics(app);
    

export const requestForToken = async () => {
    try {
        const messaging = getMessaging(app);
        let token = await getToken(messaging)
        return token
    } catch (error) {
        console.log(error)
        return null
    }
};
