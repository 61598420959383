import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_ENDPOINT

const login = (identifier, password) => {
    return axios
        .post(API_URL + "/api/auth/local", {
            identifier,
            password,
        })
        .then((response) => {
            console.log(response)
            if (response.data.jwt) {
                localStorage.setItem("user", JSON.stringify(response.data.user));
                localStorage.setItem("token", JSON.stringify(response.data.jwt));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
  };

const authService = {
    // register,
    login,
    logout,
  };

export default authService;