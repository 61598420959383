import React from 'react'
import logo from '../../assets/images/logo-light.png'

const Navbar = () => {
    const nav = {
        height: '68px',
        padding: '0px 5%',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 35,
        background: 'rgba(55, 119, 135, 0.05)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
        backdropFilter: 'blur(10px)',
        /* Note: backdrop-filter has minimal browser support */
        borderRadius: '12px'
    }

    const logoWrapper = {
        maxWidth: '120px',
        height: '100%',
        display: 'flex'
    }

    const img = {
        width: '100%',
        height: 'auto',
        margin: 'auto'
    }

    return (
        <nav className='max-w-screen-lg w-full flex justify-between items-center' style={nav}>
            <div style={logoWrapper} >
                <img src={logo} style={img} alt='sekabet light logo'></img>
            </div>
            <div className='flex flex-row items-center' >
                <a href='#appstore-slider' >
                    <img className='m-auto max-w-[40px] mr-4' src={require('../../assets/images/apple-transparent-icon.png')} />
                </a>
                <a href='#google-slider' >
                    <img className='m-auto max-w-[40px]' src={require('../../assets/images/android-transparent-icon.png')} />
                </a>
            </div>
        </nav>
    )
}

export default Navbar