import base from "axios";

export const axiosWebsiteInstance = base.create({
    headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_WEBSITE_TOKEN}`
    },
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT
})

axiosWebsiteInstance.interceptors.response.use(
    response => response,
    error => {
        console.log(error)
        return error.response

    });

export const axiosInstance = base.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        "Access-Control-Allow-Credentials": true
    }
})

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        console.log(error)
        return error.response

    });

export const axiosBackendInstance = base.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
    headers : {
        "Authorization" : `Bearer ${localStorage.getItem('token')}`
    }
})

axiosBackendInstance.interceptors.response.use(
    response => response,
    error => {
        console.log(error)
        return error.response

    });
